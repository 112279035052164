<template>
  <div>
    <b-row>
      <b-col md="4">
        <b-form-group
          :label="iso !== 'PT' ? $t('Nº de registo') : $t('Número')"
        >
          <b-form-input
            v-if="view === false"
            :id="`sw086s05_${(index + 1)}`"
            v-model="sw086[index].sw086s05"
            placeholder=""
            autocomplete="off"
          />
          <p
            v-else
            class="text-primary mb-0"
          >
            {{ getTxt(sw086[index].sw086s05) }}
          </p>
        </b-form-group>
      </b-col>
      <b-col md="1">
        <b-form-group
          :label="iso !== 'PT' ? $t('Livro') : $t('Fração')"
        >
          <b-form-input
            v-if="view === false"
            :id="`sw086s06_${(index + 1)}`"
            v-model="sw086[index].sw086s06"
            maxlength="2"
            placeholder=""
            autocomplete="off"
          />
          <p
            v-else
            class="text-primary mb-0"
          >
            {{ getTxt(sw086[index].sw086s06) }}
          </p>
        </b-form-group>
      </b-col>
      <b-col md="4">
        <b-form-group
          :label="iso !== 'PT' ? $t('Nº de propriedade') : $t('Conservatória')"
        >
          <b-form-input
            v-if="view === false"
            :id="`sw086s07_${(index + 1)}`"
            v-model="sw086[index].sw086s07"
            placeholder=""
            autocomplete="off"
          />
          <p
            v-else
            class="text-primary mb-0"
          >
            {{ getTxt(sw086[index].sw086s07) }}
          </p>
        </b-form-group>
      </b-col>
      <b-col
        v-if="addBtn === true && view === false"
        md="2"
      >
        <b-form-group
          :label="$t('Ações')"
        >
          <b-button
            v-ripple.400="'rgba(113, 102, 240, 0.15)'"
            variant="outline-primary"
            class="btn-icon"
            @click.stop.prevent="$store.dispatch('listings_form/addNewConservatoria')"
          >
            <feather-icon
              icon="PlusIcon"
              class="mr-50"
            />
            <span class="align-middle">{{ $t('Adicionar') }}</span>
          </b-button>
        </b-form-group>
      </b-col>
      <b-col
        v-if="addBtn === false && view === false"
        md="2"
      >
        <b-form-group
          :label="$t('Ações')"
        >
          <b-button
            v-ripple.400="'rgba(113, 102, 240, 0.15)'"
            variant="outline-danger"
            class="btn-icon"
            @click.stop.prevent="$store.dispatch('listings_form/removeConservatoria', index)"
          >
            <feather-icon
              icon="Trash2Icon"
              class="mr-50"
            />
            <span class="align-middle">{{ $t('Eliminar') }}</span>
          </b-button>
        </b-form-group>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import {
  BRow, BCol, BFormGroup, BFormInput, BButton,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'

export default {
  components: {
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BButton,
  },
  directives: {
    Ripple,
  },
  props: {
    sw086: {
      type: Array,
      default: () => {},
    },
    index: {
      type: Number,
      default: () => 0,
    },
    addBtn: {
      type: Boolean,
      default: () => false,
    },
    view: {
      type: Boolean,
      default: () => false,
    },
    iso: {
      type: String,
      default: () => 'PT',
    },
  },
  data: () => ({
  }),
  methods: {
    getTxt(value) {
      if ((value !== null) && (value !== undefined) && (value !== '')) {
        return value
      }
      return '-----'
    },
  },
  setup() {
    return {
    }
  },
}
</script>

<style lang="scss">
</style>
